export const workStateList = [
    {
        key: 'free',
        display: '空闲'
    },
    {
        key: 'recommended',
        display: '推荐'
    },
    {
        key: 'interviewed',
        display: '面试'
    },
    {
        key: 'probation',
        display: '试用'
    },
    {
        key: 'extended_probation',
        display: '延迟试用'
    },
    {
        key: 'job',
        display: '在职'
    }
]

export const workStateMap = {
    'free': {
        key: 'free',
        display: '空闲',
        className: 'y-color-T1'
    },
    'recommended': {
        key: 'recommended',
        display: '推荐',
        className: 'y-color-B1'
    },
    'interviewed': {
        key: 'interviewed',
        display: '面试',
        className: 'y-color-B1'
    },
    'probation': {
        key: 'probation',
        display: '试用',
        className: 'y-color-B1'
    },
    'extended_probation': {
        key: 'extended_probation',
        display: '延迟试用',
        className: 'y-color-O1'
    },
    'employed': {
        key: 'job',
        display: '在职',
        className: 'y-color-B1'
    }
}

// 专家级别
export const levelTypeList = [
    {
        key: 'junior',
        display: '初级'
    },
    {
        key: 'senior',
        display: '资深'
    },
    {
        key: 'expert',
        display: '专家'
    }
]

export const levelTypeMap = {
    'junior': {
        key: 'junior',
        display: '初级',
        className: 'y-color-O2'
    },
    'senior': {
        key: 'senior',
        display: '资深',
        className: 'y-color-O1'
    },
    'expert': {
        key: 'expert',
        display: '专家',
        className: 'y-color-O0'
    }
}


export const showRecommendList = [
    {
        key: 0,
        display: '未推荐'
    },
    {
        key: 1,
        display: '推荐'
    }
]

export const showRecommendMap = {
    0: {
        key: 0,
        display: '未推荐',
        className: 'y-color-R1'
    },
    1: {
        key: 1,
        display: '推荐',
        className: 'y-color-B1'
    }
}

